























































































import Vue from 'vue'
import pluralize from 'pluralize'
// import chunk from 'chunk'
// import flatten from 'just-flatten-it'
// import { SearchClient } from 'algoliasearch'
// import { getAlgoliaClient } from '../../services/algolia'
import { getElasticSearchClient } from '@/services/elasticSearch'
// import FulfillmentGroupForm from './FulfillmentGroupForm.vue'

// const indexName = 'Ecommerce_OrdersService_FulfillmentGroups'

interface FulfillmentGroup {
  fulfillmentGroupId: string
  createType: 'auto' | 'manual'
  title: string[]
  imageUrl: string
  productIds: string[]
  isUnfilfilled?: boolean
}

export default Vue.extend({
  name: 'FulfillmentGroups',
  data() {
    return {
      // algolia: null as SearchClient | null,
      loading: false,
      fulfillmentGroups: [] as FulfillmentGroup[],
    }
  },
  computed: {
    fulfilled() {
      // @ts-ignore
      return this.fulfillmentGroups.filter(
        (ffg: FulfillmentGroup) => !ffg.isUnfilfilled
      )
    },
    unfulfilled() {
      // @ts-ignore
      return this.fulfillmentGroups.filter(
        (ffg: FulfillmentGroup) => ffg.isUnfilfilled
      )
    },
  },
  created() {
    this.fetch()
  },
  methods: {
    async fetch() {
      const elasticSearch = getElasticSearchClient()
      const { data } = await elasticSearch.post('/fulfillment_groups/_search', {
        query: {
          match_all: {},
        },
        size: 10000,
      })
      this.fulfillmentGroups = data.hits.hits.map((hit: any) => {
        return {
          fulfillmentGroupId: hit._id,
          createType: hit._source.createType,
          title: hit._source.title,
          imageUrl: hit._source.imageUrl,
          productIds: hit._source.productIds,
          isUnfilfilled: hit._source.isUnfilfilled,
        }
      })

      const promises = this.fulfillmentGroups.map(async (fulfillmentGroup) => {
        const result = await elasticSearch.post('/fulfillments/_search', {
          query: {
            bool: {
              filter: [
                {
                  terms: { 'product.productId': fulfillmentGroup.productIds },
                },
                { term: { status: 'open' } },
                { term: { paymentStatus: 'paid' } },
                { term: { requiresShipping: true } },
                { terms: { fulfillmentStatus: ['partial', 'unfulfilled'] } },
              ],
            },
          },
          size: 0,
        })

        if (result.data.hits.total.value > 0) {
          fulfillmentGroup.isUnfilfilled = true
        }
      })

      await Promise.all(promises)
    },
    getGroupProductSizeText(group: Record<string, any>) {
      return `${group.productIds.length} ${pluralize(
        'product',
        group.productIds.length
      )}`
    },
    setLoading(value: boolean) {
      this.loading = value
    },
    getImageUrl(imageUrl: string) {
      return imageUrl && imageUrl !== ''
        ? `${process.env.VUE_APP_PREVIEW_FETCH_URL}/fetch/image/w_400,h_400,f_cover/${imageUrl}`
        : 'https://bulma.io/images/placeholders/1280x960.png'
    },
  },
})
